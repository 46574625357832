import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCityService {
  private endpoints: {
    crudCity: string,
    allCities: string,
    allCitiesBasic: string,
    parseGPTItinerary: string,
    cityItinerary: string,
    uploadCityImg: string,
    uploadOtherCityImg: string,
    deleteOtherCityImg: string,
    uploadCityImgGss: string,
    setSuntimes: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      crudCity: environment.apiUrl + 'city/',
      allCities: environment.apiUrl + 'cities/',
      allCitiesBasic: environment.apiUrl + 'cities_basic/',
      parseGPTItinerary: environment.apiUrl + 'upload_parse_itin/',
      cityItinerary: environment.apiUrl + 'city_itinerary/',
      uploadCityImg: environment.apiUrl + 'upload_city_image/',
      uploadOtherCityImg: environment.apiUrl + 'upload_other_city_image/',
      deleteOtherCityImg: environment.apiUrl + 'delete_other_city_image/',
      uploadCityImgGss: environment.apiUrl + 'upload_city_img_gss/',
      setSuntimes: environment.apiUrl + 'set_current_suntimes/',
    };
  }

  // CITY

  /* Gets all Cities */
  getCities(): Observable<any> {
    const params = new HttpParams()
      .set('trashed', 'ok');
    return this.http.get<any>(this.endpoints.allCities, {params: params});
  }

  /* Gets all Cities */
  getCitiesBasic(): Observable<any> {
    return this.http.get<any>(this.endpoints.allCitiesBasic);
  }

  /* Gets a particular City */
  getCity(city_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.crudCity + city_id);
  }

  /* Stores a particular City */
  saveCity(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.crudCity, data);
  }

  /* Updates a particular City */
  updateCity(city_id: number, data: any): Observable<any> {
    return this.http.put<any>(this.endpoints.crudCity + city_id, data);
  }

  /* Updates a particular cover img City */
  uploadCityImage(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadCityImg, data);
  }

  /* Updates a particular GSS img City */
  uploadCityGSSImage(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadCityImgGss, data);
  }

  /* Updates other image City */
  uploadOtherImage(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadOtherCityImg, data);
  }

  /* deletes other image city */
  deleteOtherImage(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.deleteOtherCityImg, data);
  }

  /* Deletes a particular City */
  deleteCity(city_id: string, force?: string): Observable<any> {
    let params = new HttpParams()
      .set('city_id', city_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudCity, {params: params});
  }

  // CITY + ITINERARY

  /* Upload FI pdf for Chat GPT */
  uploadFinalItineraryGPT(data): Observable<any> {
    return this.http.post<any>(this.endpoints.parseGPTItinerary, data);
  }

  /* Add City+Itinerary*/
  addCityItinerary(data): Observable<any> {
    return this.http.post<any>(this.endpoints.cityItinerary, data);
  }

  /* Update City+Itinerary*/
  updateCityItinerary(data): Observable<any> {
    return this.http.put<any>(this.endpoints.cityItinerary, data);
  }

  // SUNTIMES
  setSunTimes(data): Observable<any> {
    return this.http.post<any>(this.endpoints.setSuntimes, data);
  }

}
