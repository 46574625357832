import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Event} from '../../../../../../shared/models/event.model';
import {TimeChangerComponent} from '../../../../../components/tour/time-changer/time-changer.component';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {ActivetourService} from '../../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../../shared/services/common/snackbar.service';
import {Activetour} from '../../../../../../shared/models/activetour.model';
import {User} from '../../../../../../shared/models/user.model';
import {AuthenticationService} from '../../../../../../shared/services/session/authentication.service';
import {Subject} from 'rxjs';
import {HttpItineraryService} from '../../../../../../shared/services/http/http-itinerary.service';
import {Components} from '../../../../../../shared/models/components.model';
import {HttpComponentService} from '../../../../../../shared/services/http/http-component.service';
import {TourService} from '../../../../../../shared/services/common/tour.service';
import {DatePipe} from '@angular/common';
import {CopyComponentComponent} from '../../../../../components/tour/copy-component/copy-component.component';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;

  viewAs: number;
  req_id: number;

  deleting: boolean;
  longPressed: boolean;
  isSafari: boolean;
  isMobile: boolean;

  publicNotes: string;

  ub = false;
  et = false;
  gy = false;
  ad = false;
  ga = false;

  @Input() public event: Event;
  @Input() public lastItem: boolean;
  @Input() public show_copy: boolean;
  @Input() public show_ids: boolean;
  @Input() public from: string;
  @Output() delComp = new EventEmitter();
  @Output() addDupeEvent = new EventEmitter();
  @Output() reorderEvents = new EventEmitter();
  @Output() updComp = new EventEmitter();
  @Output() updEvent = new EventEmitter();
  private onDestroy$ = new Subject<boolean>();

  @HostListener('touchstart', ['$event']) onTouch() {
    this.longPressed = false;
  }

  @HostListener('mousemove', ['$event']) onMove() {
    this.longPressed = false;
  }

  constructor(
    private authSvc: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private tourSvc: TourService,
    private httpItin: HttpItineraryService,
    private httpCompSvc: HttpComponentService,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
        this.viewAs = this.route.snapshot.queryParams.viewAs || this.currentUser && this.currentUser.view_as || null;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.ub = tour?.tour.company_id === 2;
        this.et = tour?.tour.company_id === 3;
        this.ad = tour?.tour.company_id === 4;
        this.gy = tour?.tour.company_id === 5;
        this.ga = tour?.tour.company_id === 6;
      });
  }

  ngOnInit(): void {
    this.publicNotes = this.cleanHTML(this.event.public_notes);
  }

  cleanHTML(html) {
    if (!html) {
      return null;
    }
    html = html.replace(/<br>/gi, '\n').replace(/<\/p>/gi, '\n').replace(/<p[^>]*>/gi, '');
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || null;
  }

  goComponent(event) {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'day', event.day_id, 'component', event.component_id]);
  }

  goComp(c_id) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['admin', 'comps'], {queryParams: {c: c_id}}));
    window.open(url, '_blank');
  }

  onQuickChangeTime(event: Event) {
    // Avoid multiple long press
    if (this.longPressed) {
      return;
    }
    const dialogRef = this.dialog.open(TimeChangerComponent, {
      maxHeight: '90vh',
      data: {
        'time': event.start_time,
        'sc': event.component.comp_type_id === 23,
        'isMobile': this.isMobile,
        'isSafari': this.isSafari,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'close') {
        event.start_time = result['time'];
        this.reorderEvents.emit();
        this.onEditEvent(event, 'start_time', result['time']);
      } else {
        // console.log('Didnt change time');
      }
    });
    this.longPressed = true;
  }

  onEditEvent(event, field, value) {
    const params = {
      'req_id': this.currentUser.id,
    };
    if (field === 'visibility') {
      params['visible'] = value;
      params['show_times'] = value;
    } else {
      params[field] = value;
    }
    this.httpItin.updateEvent(event.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          // Find the event in memory
          const eveIndex = this.activeTour.events.findIndex(ev => '' + ev.id === '' + event.id);
          // Update in activeTour memory the new visibility
          this.activeTour.events[eveIndex] = res.results.event;
          this.activeTourSvc.setActiveTour(this.activeTour);
        } else {
          event.visible = !value;
          event.show_times = !value;
          if (res.results) {
            this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
          } else {
            console.log(res.message.toString());
          }
        }
      }, error => {
        console.log(error);
      });
  }

  toggleEventVis(event, force?: boolean) {
    if (event.component.comp_type_id === 23) {
      return;
    }
    if (force || !event.visible) {
      this.tourSvc.toggleVis(event.component, event, true)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            this.updEvent.emit(event);
          },
          error => {
            console.log(error);
          }
        );
      return;
    } else {
      event.show_times = +!event.show_times;
    }
    const params = {
      'req_id': this.currentUser.id,
      'show_times': event.show_times,
    };
    this.httpItin.updateEvent(event.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status === 203) {
          event = res.results.event;
          // Find the event in memory
          const eveIndex = this.activeTour.events.findIndex(ev => '' + ev.id === '' + event.id);
          // Update in activeTour memory the new show_times
          this.activeTour.events[eveIndex] = res.results.event;
          this.updEvent.emit(event);
        } else {
          event.show_times = +!event.show_times;
          if (res.results) {
            this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
          } else {
            console.log(res.message.toString());
          }
        }
      }, error => {
        console.log(error);
      });
  }

  toggleStatus(comp: Components) {
    const old_state = comp.state;
    const old_conf = comp.confirmed_at;
    if (old_state !== 'PEND' && !old_conf) {
      comp.state = null;
      comp.confirmed_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'UTC');
    } else if (old_conf) {
      comp.state = 'PEND';
      comp.confirmed_at = null;
    } else {
      comp.state = null;
      comp.confirmed_at = null;
    }
    const data = {
      'confirmed_at': comp.confirmed_at,
      'state': comp.state,
    };
    // console.log(data);
    this.httpCompSvc.updateComponent(comp.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.updComp.emit(comp);
          } else {
            comp.state = old_state;
            comp.confirmed_at = old_conf;
            this.snackSvc.openSnackBar('ERROR. Updating confirmation');
          }
        },
        error => {
          comp.state = old_state;
          comp.confirmed_at = old_conf;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating confirmation');
        });
  }

  onDelSMComp(comp) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete component?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.onDeleteComponent(comp);
        }
      });
  }

  onDeleteComponent(comp) {
    this.deleting = true;
    this.httpCompSvc.deleteComponent(comp.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.delComp.emit(comp.id);
            // If dismiss or no action delete the component + event from memory
            const comIdx = this.activeTour.components.findIndex(c => +c.id === +comp.id);
            this.activeTour.components.splice(comIdx, 1);
            // Delete its associated events
            this.activeTour.events = this.activeTour.events.filter(e => +e.component_id !== +comp.id);
            this.activeTourSvc.setActiveTour(this.activeTour);
          } else {
            // Error deleting city component in DB
            this.snackSvc.resultsElse(res);
          }
          this.deleting = false;
        },
        error => {
          this.deleting = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Deleting component');
        });
  }

  onDuplicateComponent(component) {
    const comp = this.activeTour.components.find(c => +c.id === +component.id);
    if (comp && comp.events.length > 0) {
      component.events = comp.events;
    }
    const dialogRef = this.dialog.open(CopyComponentComponent, {
      data: {'autoFocus': true, 'component': component, 'user': this.currentUser}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        this.activeTour.components.push(result['component']);
        this.activeTour.events.push(result['events']);
        this.activeTourSvc.setActiveTour(this.activeTour);
        this.addDupeEvent.emit(result);
        this.snackSvc.openSnackBar('Component duplicated');
        return;
      } else {
        console.log('Don\'t copy component');
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
