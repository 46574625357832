import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthGuard} from '../../../../shared/guards/auth.guard';
import {MainNewTourComponent} from './pages/main-new-tour/main-new-tour.component';
import {SelectPaxComponent} from './pages/select-pax/select-pax.component';
import {SummaryNewTourComponent} from './pages/summary-new-tour/summary-new-tour.component';
import {SelectItinComponent} from './pages/select-itin/select-itin.component';

const newTourRoutes = [
  {
    path: 'new', canActivate: [AuthGuard], children: [
      {path: '', component: MainNewTourComponent},
    ]
  },
  {
    path: 'pax', canActivate: [AuthGuard], children: [
      {path: '', component: SelectPaxComponent},
      {path: ':tour-prodid', component: SelectPaxComponent}
    ]
  },
  {
    path: 'itinerary', canActivate: [AuthGuard], children: [
      {path: '', component: SelectItinComponent},
      {path: ':tour-prodid', component: SelectItinComponent}
    ]
  },
  {
    path: 'summary', canActivate: [AuthGuard], children: [
      {path: '', component: SummaryNewTourComponent},
      {path: ':tour-prodid', component: SummaryNewTourComponent}
    ]
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(newTourRoutes),
  ],
  exports: [RouterModule]
})
export class NewTourRoutingModule {
}
