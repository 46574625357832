import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {TextTransformService} from '../../../../../shared/helpers/texttransform.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {Observable, of, Subject} from 'rxjs';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Itinerary} from '../../../../../shared/models/itinerary.model';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {DatePipe} from '@angular/common';
import {HttpItineraryService} from '../../../../../shared/services/http/http-itinerary.service';
import {Gsstype} from '../../../../../shared/models/gsstype.model';
import {HttpCityService} from '../../../../../shared/services/http/http-city.service';
import {OutlookService} from '../../../../../shared/services/session/outlook.service';
import {Title} from '@angular/platform-browser';
import {City} from '../../../../../shared/models/city.model';
import {FlagService} from '../../../../../shared/services/common/flag.service';
import {HttpHotelService} from '../../../../../shared/services/http/http-hotel.service';
import {Hotel} from '../../../../../shared/models/hotel.model';
import {UntypedFormControl} from '@angular/forms';
import {AddCityComponent} from '../../../../components/add-city/add-city.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../../environments/environment';
import {Clipboard} from '@angular/cdk/clipboard';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';
import {TourService} from '../../../../../shared/services/common/tour.service';

@Component({
  selector: 'app-fitinerary',
  templateUrl: './fitinerary.component.html',
  styleUrls: ['./fitinerary.component.scss'],
})
export class FitineraryComponent implements OnInit, OnDestroy {
  // Active tour info
  activeTour: Activetour;
  currentUser: User;
  newItin: Itinerary;
  gssTypes: Gsstype[];
  hotel: Hotel;
  prod_id: string;
  loading: boolean;
  loadingPDF: boolean;
  onNext: boolean;
  generating = false;

  new_nights = 1;
  returnUrl: string;
  city_f_name: string;
  city_f_name2: string;

  editable = true;
  showScanner = true;
  file: any;
  addCityManually = false;
  travelOpts: any[];

  cities: City[] = [];
  hotels: Hotel[] = [];
  hotels_city: Hotel[] = [];

  idxAppendDateErrClass: any[] = [];
  idxAppendEditClass: any[] = [];
  idxRevCity: any[] = [];

  cityName = new UntypedFormControl();
  hotelName = new UntypedFormControl();
  cityName2 = new UntypedFormControl();
  hotelName2 = new UntypedFormControl();
  filteredCities: Observable<City[]>;
  filteredCities2: Observable<City[]>;
  filteredHotelOptions: Observable<Hotel[]>;
  filteredHotelOptions2: Observable<Hotel[]>;

  private onDestroy$ = new Subject<boolean>();
  @ViewChild('checkin') searchElementCheckinRef: ElementRef;
  @ViewChildren('itins') searchElementItinRefs: QueryList<ElementRef>;

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Escape') {
      this.idxAppendEditClass = [];
      this.closeMenu();
    }
  }

  constructor(
    public dialog: MatDialog,
    private httpTour: HttpTourService,
    private httpItin: HttpItineraryService,
    private httpCitySvc: HttpCityService,
    private httpHotel: HttpHotelService,
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
    private titleSvc: TitleService,
    private webTitleSvc: Title,
    private versioning: VersioningService,
    private outlookSvc: OutlookService,
    private textTransform: TextTransformService,
    private router: Router,
    private tourSvc: TourService,
    private clipboard: Clipboard,
    private datePipe: DatePipe,
    private flagService: FlagService,
    private route: ActivatedRoute
  ) {
    this.titleSvc.setTitle('Itinerary');
    this.webTitleSvc.setTitle('Itinerary | Planafy');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
  }

  numericOnly(e) {
    return e.key !== '+' && e.key !== '-' && e.key !== 'e' && e.key !== 'E' && e.key !== '.' && e.key !== ',';
  }

  ngOnInit() {
    this.onNext = false;
    this.loading = false;
    this.travelOpts = [
      {'id': 1, 'description': 'Bus'},
      {'id': 2, 'description': 'Train'},
      {'id': 3, 'description': 'Plane'},
      {'id': 4, 'description': 'Boat'}];
    this.gssTypes = JSON.parse(localStorage.getItem('gssTypes'));
    this.prod_id = this.route.snapshot.params['tour-prodid'];
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    if (this.activeTour.tour.prodid + '' !== this.prod_id) {
      this.snackSvc.openSnackBar('Error. Wrong tour?');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
      this.snackSvc.openSnackBar('Error. Not your tour?');
      this.router.navigate(['tours']);
      return;
    }
    this.newItin = new Itinerary();
    this.newItin.city.gss_type = this.gssTypes.find(g => g.id === 1);
    this.activeTour.itinerary = [];
    this.getTourItinerary();
    this.getAllCities();
    this.getAllHotels();
  }

  private _filter(value: any): City[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter(option => option.name.toLowerCase().includes(filterValue) || option.country.toLowerCase().includes(filterValue));
  }

  private _filterH(value: any): Hotel[] {
    const filterValue = value.toLowerCase();
    return this.hotels_city.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFn(city: City): string {
    return city && city.name ? city.name : '';
  }

  displayFnH(hotel: Hotel): string {
    return hotel && hotel.name ? hotel.name : '';
  }

  displayFn2(city?: City): string {
    return city && city.name ? city.name : '';
  }

  displayFnH2(hotel?: Hotel): string {
    return hotel && hotel.name ? hotel.name : '';
  }

  addManually() {
    this.newItin.city.gss_type = this.gssTypes.find(g => g.id === 1);
    this.addCityManually = true;
    this.showScanner = false;
    this.idxAppendEditClass = [];
    // Max checkout
    if (this.activeTour.itinerary.length > 0 && this.activeTour.itinerary.every(i => i.check_out)) {
      const max_co = new Date(Math.max.apply(Math, this.activeTour.itinerary.map(function (o) {
        return new Date(o.check_out);
      })));
      this.newItin.check_in = this.datePipe.transform(max_co, 'yyyy-MM-dd');
    }
    // Scroll to checkin
    setTimeout(() => {
      this.searchElementCheckinRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 1);
  }

  printIt() {
    console.log(this.cities);
    console.log(this.hotels);
    this.getTourItinerary();
  }

  /*
/* HTTP REQUESTS
*******************/
  getTourItinerary() {
    this.loading = true;
    this.httpItin.getItineraryTour('' + this.activeTour.tour.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          const itins = res.results.itinerary.filter(itin => itin.travel_by !== '6');
          if (res.status < 400) {
            this.activeTour.itinerary = this.sortArrayFields(itins, ['check_in']);
            this.showScanner = this.activeTour.itinerary.length < 1;
            this.editable = this.showScanner || !res.results.tour_paid;
            this.activeTour.itinerary.map(item => {
              if (!item.city) {
                item.city = new City();
              }
            });
            itins.map(it => {
              if (!it.hotel) {
                it.hotel = new Hotel();
              }
            });
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.loading = false;
          } else {
            this.loading = false;
            this.snackSvc.resultsElse(res);
            this.router.navigate(['tours']);
          }
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('Error. Contact support');
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error getItineraryTour()', JSON.stringify(error));
        });
  }

  getAllCities() {
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.versioning.check(res.results.version);
            // Insert all cities
            this.cities = res.results.cities;
            this.filteredCities = this.cityName.valueChanges
              .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filter(name) : this.cities.slice())
              );
            this.filteredCities2 = this.cityName2.valueChanges
              .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filter(name) : this.cities.slice())
              );
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  getAllHotels() {
    this.httpHotel.getAllHotels()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Insert all cities
            this.hotels = res.results.hotels;
            this.hotels_city = this.hotels;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all hotels');
        });
  }

  inputCityName(ev, it: Itinerary) {
    if (ev.inputType === 'deleteContentBackward') {
      it.city = new City();
      it.hotel = new Hotel();
      it.hotel_name = '';
      this.hotelName.setValue(it.hotel);
      this.hotelName2.setValue(it.hotel);
      this.filteredHotelOptions = of([]);
      this.filteredHotelOptions2 = of([]);
    }
    it.disable_country = false;
    it.city_id = null;
    it.hotel.id = null;
    it.hotel_id = null;
  }

  onSelectCityChanged(city, it, txt) {
    it.city = city;
    it.city_id = it.city.id;
    it.disable_country = true;
    // Filter hotels to city chosen
    this.hotels_city = this.hotels.filter(option => option.city_id === it.city_id);
    if (txt === 'newItin') {
      this.city_f_name = city.name;
      this.filteredHotelOptions = this.hotelName.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterH(name) : this.hotels_city.slice())
        );
      this.hotelName.setValue('');
    } else {
      this.city_f_name2 = city.name;
      this.filteredHotelOptions2 = this.hotelName2.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterH(name) : this.hotels_city.slice())
        );
      this.hotelName2.setValue('');
    }
    this.inputHotelName({target: {value: ''}}, it);
  }

  inputHotelName(ev, it) {
    it.hotel_id = null;
    it.hotel = new Hotel();
    it.hotel.name = ev.target.value;
    it.hotel_name = ev.target.value;
  }

  onSelectHotelChanged(ev, it) {
    it.hotel = ev.option.value;
    it.hotel_id = it.hotel.id;
    it.hotel_name = it.hotel.name;
  }

  // CHAT GPT

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  prepareFilesList(file) {
    if (this.loadingPDF) {
      return;
    }
    this.file = file[0];
    if (!this.file.type.includes('pdf')) {
      // this.file = null;
      this.snackSvc.openSnackBar('File must be a pdf');
      return;
    }
    if (!this.file.name.toLowerCase().includes('itinerary')) {
      // this.file = null;
      this.snackSvc.openSnackBar('Upload Final Itinerary pdf');
      return;
    }
    this.versionGPT();
  }

  versionGPT() {
    this.loadingPDF = true;
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('user_id', '' + this.currentUser.id);
    formData.append('tour_id', '' + this.activeTour.tour.id);
    // console.log('formData', formData);
    this.httpCitySvc.uploadFinalItineraryGPT(formData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.getTourItinerary();
          this.file = null;
        } else {
          let error;
          if (res.results) {
            error = res.results[Object.keys(res.results)[0]].toString();
          } else {
            error = res.message.toString();
          }
          this.snackSvc.openSnackBar(error);
        }
        this.loadingPDF = false;
      }, error => {
        this.loadingPDF = false;
        console.log(error);
        this.snackSvc.openSnackBar('Error. Try again');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error versionGPT()', JSON.stringify(error));
      });
  }

  updateCityItinerary(it) {
    if (it.city.id === 0) {
      this.addItin(it);
      return;
    }
    if (it.check_in >= it.check_out) {
      this.snackSvc.openSnackBar('Error. Check in before check out');
      this.idxAppendDateErrClass.push(it.id);
      return;
    }
    if (it.check_in === '' || it.check_out === '') {
      this.snackSvc.openSnackBar('Error. Invalid dates');
      this.idxAppendDateErrClass.push(it.id);
      return;
    }
    if (!it.city.name) {
      this.snackSvc.openSnackBar('Error. City required');
      return;
    }
    if (!it.city.country) {
      this.snackSvc.openSnackBar('Error. Country required');
      return;
    }
    if (!it.hotel || !it.hotel?.name) {
      this.snackSvc.openSnackBar('Error. Hotel name required');
      return;
    }
    if (it.id) {
      this.sortItinBy('check_in');
      this.idxAppendDateErrClass.splice(this.idxAppendDateErrClass.findIndex(i => '' + i === '' + it.id), 1);
      const params = {
        'user_id': this.currentUser.id,
        'tour_id': this.activeTour.tour.id,
        'itinerary_id': it.id,
        'check_in': it.check_in,
        'check_out': it.check_out,
        'travel_by': '' + it.travel_by,
        'flag': null,
        'name': it.city.name,
        'country': it.city.country,
        'gss_type_id': +it.city.gss_type.id,
        'local_transport': it.city.local_transport,
      };
      if (it.hotel.name) {
        params['hotel_name'] = it.hotel.name.substring(0, 50);
      }
      if (it.hotel.address) {
        params['hotel_address'] = it.hotel.address.substring(0, 255);
      }
      if (it.changed_hotelName) {
        params['changed_name'] = it.changed_hotelName;
      }
      if (it.hotel.id) {
        params['hotel_id'] = it.hotel.id;
      }
      if (it.city.id) {
        params['city_id'] = it.city.id;
      }
      // console.log('params', params);
      this.httpCitySvc.updateCityItinerary(params)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(res => {
          console.log(res);
          if (res.status === 201) {
            // Update in memory
            const idx = this.activeTour.itinerary.findIndex(itin => '' + itin.id === '' + res.results.itinerary.id);
            this.activeTour.itinerary[idx] = res.results.itinerary;
            // Remove from editable list
            const editIdx = this.idxAppendEditClass.findIndex(i => '' + i === '' + it.id);
            if (editIdx >= 0) {
              this.idxAppendEditClass.splice(editIdx, 1);
            }
            // Remove from new city if it was
            const newIdx = this.idxRevCity.findIndex(i => '' + i === '' + it.id);
            if (newIdx >= 0) {
              this.idxRevCity.splice(newIdx, 1);
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        }, error2 => {
          console.log(error2);
          this.snackSvc.openSnackBar('Error. Contact support');
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error updateCityItinerary()', JSON.stringify(error2));
        });
    }
  }

  clearIt() {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Remove everything?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          this.httpItin.deleteItinerariesTour(this.activeTour.tour.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status === 204) {
                  this.newItin = new Itinerary();
                  this.newItin.city.gss_type = this.gssTypes.find(g => g.id === 1);
                  this.new_nights = 1;
                  this.showScanner = true;
                  this.generating = false;
                  this.activeTour.itinerary = [];
                  this.activeTour.components = [];
                  this.activeTour.days = [];
                  this.activeTour.events = [];
                  this.activeTour.orders = [];
                  this.activeTourSvc.setActiveTour(this.activeTour);
                  // this.clipboard.copy(' ');
                } else {
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    console.log(res.message.toString());
                  }
                }
              },
              error => {
                console.log(error);
                this.snackSvc.openSnackBar('Error. Contact support');
                this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error deleteItinerariesTour', JSON.stringify(error));
              });
        }
      });
  }

  toggleGSSaudio(it: Itinerary) {
    switch (it.city.gss_type.id) {
      case 1:
        it.city.gss_type = this.gssTypes.find(g => g.id === 3);
        break;
      case 2:
        it.city.gss_type = this.gssTypes.find(g => g.id === 3);
        break;
      case 3:
        it.city.gss_type = this.gssTypes.find(g => g.id === 2);
        break;
      case 4:
        it.city.gss_type = this.gssTypes.find(g => g.id === 5);
        break;
      case 5:
        it.city.gss_type = this.gssTypes.find(g => g.id === 4);
        break;
      default:
        break;
    }
  }

  toggleGSS(it: Itinerary) {
    switch (it.city.gss_type.id) {
      case 1:
        it.city.gss_type = this.gssTypes.find(g => g.id === 2);
        break;
      default:
        it.city.gss_type = this.gssTypes.find(g => g.id === 1);
        break;
    }
  }

  toggleGSSbus(it: Itinerary) {
    switch (it.city.gss_type.id) {
      case 1:
        it.city.gss_type = this.gssTypes.find(g => g.id === 4);
        break;
      case 2:
        it.city.gss_type = this.gssTypes.find(g => g.id === 4);
        break;
      case 3:
        it.city.gss_type = this.gssTypes.find(g => g.id === 5);
        break;
      case 4:
        it.city.gss_type = this.gssTypes.find(g => g.id === 2);
        break;
      case 5:
        it.city.gss_type = this.gssTypes.find(g => g.id === 3);
        break;
      default:
        break;
    }
  }

  info() {
    if (!environment.production) {
      console.log(this.activeTour);
    }
  }

  printItin(it) {
    if (!environment.production) {
      console.log(it);
    }
  }

  orderCheckin() {
    this.activeTour.itinerary = this.sortArrayFields(this.activeTour.itinerary, ['check_in']);
    this.activeTourSvc.setActiveTour(this.activeTour);
  }

  checkItin(silent?): boolean {
    this.orderCheckin();
    if (this.activeTour.itinerary.length < 1) {
      this.snackSvc.openSnackBar('Error. Add at least one city');
      return false;
    }
    // Check if theres a ciy missing
    const idx = this.activeTour.itinerary.findIndex(it => it.city === null);
    if (idx >= 0) {
      this.activeTour.itinerary[idx].city = new City();
      this.idxAppendEditClass.push(this.activeTour.itinerary[idx].id);
      this.idxRevCity.push(this.activeTour.itinerary[idx].id);
      this.snackSvc.openSnackBar('Error. Add all city names');
      return false;
    }
    // Check if theres a travel by missing
    this.activeTour.itinerary.map((it, i, all) => {
      if ((it.travel_by === 'new' || !it.travel_by) && (i + 1 !== all.length)) {
        it.flag = 'travel';
      }
    });
    this.activeTourSvc.setActiveTour(this.activeTour);
    const idxTb = this.activeTour.itinerary.findIndex(it => it.flag === 'travel');
    if (idxTb >= 0) {
      this.snackSvc.openSnackBar('Error. Add transport');
      return false;
    }
    this.activeTourSvc.setActiveTour(this.activeTour);
    // Check that all dates are ok
    const idx_err = this.activeTour.itinerary.map(function (i) {
      return (i.check_in.toString() === '' || i.check_out.toString() === '');
    }).indexOf(true);
    if (idx_err > -1) {
      this.snackSvc.openSnackBar('Error. Invalid dates');
      this.idxAppendDateErrClass.push(this.activeTour.itinerary[idx_err].id);
      return false;
    }
    // Check that within each itinerary, dates are ok
    const idx_dat = this.activeTour.itinerary.map(function (i) {
      return (i.check_in > i.check_out);
    }).indexOf(true);
    if (idx_dat > -1) {
      this.snackSvc.openSnackBar('Error. Check in before check out');
      this.idxAppendDateErrClass.push(this.activeTour.itinerary[idx_dat].id);
      return false;
    }
    // One itinerary ok
    if (this.activeTour.itinerary.length === 1) {
      if (!silent) {
        this.snackSvc.openSnackBar('Itinerary ok');
      }
      return true;
    }
    // Check that all itineraries have consecutive dates
    for (let i = 0; i < this.activeTour.itinerary.length - 1; i++) {
      const i0_chout = this.activeTour.itinerary[i].check_out;
      const i1_chin = this.activeTour.itinerary[i + 1].check_in;
      const it0 = this.activeTour.itinerary[i];
      const it1 = this.activeTour.itinerary[i + 1];
      if ((it0.travel_by !== '5' && this.textTransform.dateDiffInDays(new Date(i0_chout), new Date(i1_chin)) > 0) ||
        (it0.travel_by === '5' && this.textTransform.dateDiffInDays(new Date(i0_chout), new Date(i1_chin)) > 1)) {
        if (this.idxAppendDateErrClass.indexOf(it0.id) === -1) {
          this.idxAppendDateErrClass.push(it0.id);
        }
        if (this.idxAppendDateErrClass.indexOf(it1.id) === -1) {
          this.idxAppendDateErrClass.push(it1.id);
        }
        this.snackSvc.openSnackBar('Warning dates. Overnight boat?');
        return false;
      } else {
        this.idxAppendDateErrClass.splice(this.idxAppendDateErrClass.findIndex(iti => '' + iti === '' + it0.id), 1);
        this.idxAppendDateErrClass.splice(this.idxAppendDateErrClass.findIndex(iti => '' + iti === '' + it1.id), 1);
        if (!silent) {
          this.snackSvc.openSnackBar('Itinerary ok');
        }
        this.idxAppendEditClass = [];
      }
    }
    return true;
  }

  print4Room() {
    if (this.currentUser.id !== 2) {
      return;
    }
    this.printIt();
    let print4room = '';
    for (let j = 0; j < this.activeTour.itinerary.length; j++) {
      const line = (j + 1) + '. ' + this.activeTour.itinerary[j].city.name + ': ' + this.activeTour.itinerary[j].hotel.name + '\n';
      const date = 'From ' + this.activeTour.itinerary[j].check_in + ' to ' + this.activeTour.itinerary[j].check_out;
      print4room = print4room + line + date + '\n\n';
    }
    this.clipboard.copy(print4room);
  }

  addNewCity(itin, txt) {
    const dialogRef = this.dialog.open(AddCityComponent, {
      data: {'autoFocus': true}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'user_id': this.currentUser.id,
          'confirmed': true,
          'name': this.textTransform.titleCase(result['name']),
          'country': this.textTransform.titleCase(result['country']),
        };
        this.httpCitySvc.saveCity(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                const city = new City(res.results.city);
                const CityIdx = this.cities.findIndex(x => x.name === city.name && x.country === city.country);
                if (CityIdx < 0) {
                  this.cities.push(city);
                  localStorage.setItem('cities', JSON.stringify(this.cities));
                  this.onSelectCityChanged(res.results.city, itin, txt);
                  this.filteredCities = this.cityName.valueChanges
                    .pipe(
                      startWith(''),
                      map(value => typeof value === 'string' ? value : value.name),
                      map(name => name ? this._filter(name) : this.cities.slice())
                    );
                  this.filteredCities2 = this.cityName2.valueChanges
                    .pipe(
                      startWith(''),
                      map(value => typeof value === 'string' ? value : value.name),
                      map(name => name ? this._filter(name) : this.cities.slice())
                    );
                } else {
                  this.snackSvc.openSnackBar('City already exists');
                }
                this.cities.sort(function (a, b) {
                  return a.name[0].localeCompare(b.name[0]);
                });
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
              this.snackSvc.openSnackBar('Error. Contact support');
              this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error saveCity()', JSON.stringify(error));
            });
      }
    });
  }

  addItin(it) {
    if (it.check_in && this.new_nights) {
      it.check_out = this.textTransform.addDays(new Date(it.check_in + 'T11:59:59.000Z'), this.new_nights);
    }
    if (it.check_in === '' || it.check_out === '' || !it.check_in || !it.check_out) {
      this.snackSvc.openSnackBar('Error. Invalid dates');
      return;
    }
    if (!this.new_nights || this.new_nights < 1 || !Number.isInteger(Number(this.new_nights))) {
      this.snackSvc.openSnackBar('Error. Invalid # nights');
      return;
    }
    if (it.check_in > it.check_out) {
      this.snackSvc.openSnackBar('Error. Check in before check out');
      return;
    }
    if (it.city.name === '' || it.city.country === '' || !it.city.name || !it.city.country) {
      this.snackSvc.openSnackBar('Error. Invalid city or country');
      return;
    }
    if (it.hotel.name === '' || !it.hotel.name) {
      this.snackSvc.openSnackBar('Error. Hotel name required');
      return;
    }
    if (!it.city.gss_type.id || it.city.gss_type.id < 1) {
      this.snackSvc.openSnackBar('Error. GUSS required');
      return;
    }
    const params = {
      'user_id': this.currentUser.id,
      'tour_id': this.activeTour.tour.id,
      'city_id': it.city.id ? it.city.id : null,
      'city_name': this.textTransform.titleCase(it.city.name),
      'country': this.textTransform.titleCase(it.city.country),
      'hotel_name': it.hotel.name.substring(0, 50),
      'hotel_address': it.hotel.address?.substring(0, 255),
      'check_in': it.check_in,
      'check_out': it.check_out,
      'flag': 'new',
      'travel_by': 'new',
      'gss_type_id': it.city.gss_type.id,
      'local_transport': it.city.local_transport ? 1 : 0,
    };
    // console.log(params);
    this.httpCitySvc.addCityItinerary(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 201) {
            // If itinerary not in array, push
            const idx = this.activeTour.itinerary.findIndex(i => '' + i.id === '' + res.results.itinerary.id);
            if (idx < 0) {
              this.activeTour.itinerary.push(res.results.itinerary);
              this.sortItinBy('check_in');
              this.activeTourSvc.setActiveTour(this.activeTour);
            }
            const editIdx = this.idxAppendEditClass.findIndex(i => '' + i === '' + it.id);
            this.idxAppendEditClass.splice(editIdx, 1);
            // Min check-in
            const min_ci = new Date(Math.min.apply(Math, this.activeTour.itinerary.map(function (o) {
              return new Date(o.check_in);
            })));
            // Max checkout
            const max_co = new Date(Math.max.apply(Math, this.activeTour.itinerary.map(function (o) {
              return new Date(o.check_out);
            })));
            this.activeTour.tour.tour_starts = this.textTransform.addDays(min_ci, -1);
            // Date diff
            this.activeTour.tour.ndays = 1 + (Math.abs((max_co.getTime() - min_ci.getTime())) / (1000 * 3600 * 24));
            if (this.activeTour.tour.ndays >= 255) {
              this.snackSvc.openSnackBar('Error. Too many days of tour');
            } else {
              this.newItin.city = new City();
              this.newItin.city.gss_type = this.gssTypes.find(g => g.id === 1);
              this.newItin.hotel = new Hotel();
              this.newItin.city_id = null;
              this.newItin.hotel_id = null;
              this.newItin.flag = null;
              this.newItin.hotel_name = '';
              this.cityName.setValue('');
              this.hotelName.setValue('');
              this.new_nights = 1;
              this.newItin.check_in = this.newItin.check_out;
              this.newItin.check_out = '';
              this.newItin.disable_country = false;
              this.hotels_city = this.hotels;
            }
            // Scroll to checkin IF SM
            if (window.innerWidth <= 767) {
              setTimeout(() => {
                this.searchElementCheckinRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
              }, 1);
            }

          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
              this.snackSvc.openSnackBar(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error adding city to itinerary');
        });
  }

  closeMenu() {
    this.addCityManually = false;
    if (this.activeTour.itinerary.length <= 0) {
      this.showScanner = true;
    }
  }

  cleanItin(it) {
    it.city = new City();
    it.hotel = new Hotel();
    this.cityName.setValue('');
    this.hotelName.setValue('');
    this.new_nights = 1;
    it.check_in = '';
    it.check_out = '';
    it.disable_country = false;
    this.closeMenu();
  }

  discardEdit(it) {
    const editIdx = this.idxAppendEditClass.findIndex(i => '' + i === '' + it.id);
    this.idxAppendEditClass.splice(editIdx, 1);
    if (it.flag) {
      it.flag = null;
      this.updateItin(it, 'flag', null);
    }
  }

  editItin(it) {
    if (!this.editable) {
      return;
    }
    this.addCityManually = false;
    this.idxAppendEditClass = [];
    this.idxAppendEditClass.push(it.id);
    this.cityName2.setValue(it.city);
    this.hotelName2.setValue(it.hotel);
    this.hotels_city = this.hotels.filter(option => option.city_id === it.city_id);
    this.filteredHotelOptions2 = this.hotelName2.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterH(name) : this.hotels_city.slice())
      );
    const idx = this.searchElementItinRefs.toArray().findIndex(item => '' + item.nativeElement.id === '' + it.id);
    if (idx >= 0) {
      setTimeout(() => {
        this.searchElementItinRefs.toArray()[idx].nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest'});
      }, 1);
    }
  }

  async updateItin(it: Itinerary, txt, value) {
    if (!this.editable) {
      this.snackSvc.openSnackBar('Tour already paid. Contact support');
      return;
    }
    const old_tb = '' + it.travel_by;
    const data = {};
    if (txt === 'itin') {
      it.flag = null;
      it.travel_by = '' + value;
      data['travel_by'] = '' + value;
      data['delete_comp_transfer'] = true;
    } else if (txt === 'itin_no') {
      it.travel_by = '' + value;
      data['travel_by'] = '' + value;
      data['remove_boat_on'] = true;
    } else {
      data[txt] = value;
    }
    if (txt.includes('itin')) {
      it.loading_tb = true;
    }
    // console.log(data);
    const res = await this.httpItin.updateItinerary2(it.id, data);
    console.log(res);
    // res.results.itinerary.travel_by = '' + res.results.itinerary.travel_by;
    if (res.status < 400) {
      const idx = this.activeTour.itinerary.map(function (iti) {
        return iti.id;
      }).indexOf(res.results.itinerary.id);
      this.activeTour.itinerary[idx] = res.results.itinerary;
      this.activeTourSvc.setActiveTour(this.activeTour);
      it.loading_tb = false;
    } else {
      it.loading_tb = false;
      it.travel_by = old_tb;
      it.flag = 'travel';
      if (res.results) {
        this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
      } else {
        this.snackSvc.openSnackBar(res.message.toString());
      }
    }
  }

  deleteItin(it) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete from itinerary?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          const itemIndex = this.activeTour.itinerary.findIndex(item => '' + item.id === '' + it.id);
          this.activeTour.itinerary.splice(itemIndex, 1);
          this.activeTourSvc.setActiveTour(this.activeTour);
          this.httpItin.deleteItineraryTour(it.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                // Remove from editables
                const editIdx = this.idxAppendEditClass.findIndex(i => '' + i === '' + it.id);
                this.idxAppendEditClass.splice(editIdx, 1);
                if (res.status === 204) {
                  // Calculate ndays & tour_starts and update tour
                  if (this.activeTour.itinerary.length < 1) {
                    this.showScanner = true;
                    console.log('Empty itinerary');
                    return;
                  }
                  // Min check-in
                  const min_ci = new Date(Math.min.apply(Math, this.activeTour.itinerary.map(function (o) {
                    return new Date(o.check_in);
                  })));
                  // Max checkout
                  const max_co = new Date(Math.max.apply(Math, this.activeTour.itinerary.map(function (o) {
                    return new Date(o.check_out);
                  })));
                  // tour_starts
                  this.activeTour.tour.tour_starts = this.textTransform.addDays(min_ci, -1);
                  // Date diff
                  this.activeTour.tour.ndays = 1 + (Math.abs((max_co.getTime() - min_ci.getTime())) / (1000 * 3600 * 24));
                } else {
                  // Error deleting city itinerary in DB
                  this.activeTour.itinerary.splice(itemIndex, 0, it);
                  this.activeTourSvc.setActiveTour(this.activeTour);
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    console.log(res.message.toString());
                  }
                }
              },
              error => {
                console.log(error);
                this.snackSvc.openSnackBar('Error. Contact support');
                this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error deleteItineraryTour()', JSON.stringify(error));
              });
        }
      });
  }

  onOK() {
    const ok = this.checkItin(true);
    if (!ok) {
      return;
    }
    // ALL GOOD SET SOME PROPERTIES
    this.onNext = true;
    // Min check-in
    const min_ci = new Date(Math.min.apply(Math, this.activeTour.itinerary.map(function (o) {
      return new Date(o.check_in);
    })));
    // Max checkout
    const max_co = new Date(Math.max.apply(Math, this.activeTour.itinerary.map(function (o) {
      return new Date(o.check_out);
    })));
    // tour_starts
    this.activeTour.tour.tour_starts = this.textTransform.addDays(min_ci, -1);
    // Date diff
    this.activeTour.tour.ndays = 1 + (Math.abs((max_co.getTime() - min_ci.getTime())) / (1000 * 3600 * 24));
    // Create folder, update tour
    this.tourSvc.onCreateTourFolder(false);
    this.updateTour();
  }

  updateTour() {
    const params = {
      'req_id': this.currentUser.id,
      'ndays': this.activeTour.tour.ndays,
      'price_pday': 400,
      'tour_starts': this.activeTour.tour.tour_starts,
    };
    this.httpTour.updateTour(this.activeTour.tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.activeTour.tour = res.results.tour;
          this.activeTourSvc.setActiveTour(this.activeTour);
          this.activeTourSvc.addTour2User(this.activeTour.tour);
          this.onNext = false;
          if (this.returnUrl && this.activeTour.tour.tour_settings.paid_at) {
            this.router.navigate(['tour', this.activeTour.tour.prodid], {queryParams: {returnUrl: this.returnUrl}});
            return;
          }
          this.router.navigate(['summary', this.activeTour.tour.prodid]);

        } else {
          this.snackSvc.resultsElse(res);
          this.onNext = false;
        }
      }, error => {
        console.log(error);
        this.onNext = false;
        this.snackSvc.openSnackBar('Error. Contact support');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error updateTour: ' + JSON.stringify(error), params.toString());
      });
  }

  onCheckOvernight(e, it) {
    if (e.checked) {
      it.travel_by = '5';
      this.updateItin(it, 'itin', '' + it.travel_by);
    } else {
      it.travel_by = '4';
      this.updateItin(it, 'itin_no', '' + it.travel_by);
    }
  }

  onChangeNights() {
    if (this.newItin.check_in) {
      this.newItin.check_out = this.textTransform.addDays(new Date(this.newItin.check_in + 'T11:59:59.000Z'), this.new_nights);
    }
  }

  // Sort itinerary fields
  sortItinBy(prop: string) {
    return this.activeTour.itinerary.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  sortArrayFields(arr, field) {
    const fieldSorter = (fields) => (a, b) => fields.map(o => {
      let dir = 1;
      if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
      }
      return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);
    arr.sort(fieldSorter(field));
    return arr;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
