export class Nrooms {
  tour_id: number;
  mal: number;
  fem: number;
  sin: number;
  twn: number;
  dbl: number;
  tri: number;
  qad: number;

  constructor() {
    this.mal = 0;
    this.fem = 0;
    this.sin = 0;
    this.twn = 0;
    this.dbl = 0;
    this.tri = 0;
    this.qad = 0;
  }
}
