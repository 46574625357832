import {Component, Inject} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {Voucher} from '../../../../shared/models/voucher.model';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {User} from '../../../../shared/models/user.model';

export interface DialogDataCreateVoucher {
  user: User;
  user_id: number;
  voucher: Voucher;
  reason: string;
}

@Component({
  selector: 'app-add-voucher',
  templateUrl: './add-voucher.component.html',
  styleUrls: ['./add-voucher.component.scss']
})
export class AddVoucherComponent {
  loading = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<AddVoucherComponent>,
              private snackSvc: SnackbarService,
              private httpOrders: HttpOrdersService,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataCreateVoucher) {
    this.data.voucher = new Voucher();
    if (!this.data.user) {
      this.data.user = new User();
      this.data.user.id = this.data.user_id;
    }
  }

  nameUpper() {
    this.data.voucher.name = this.data.voucher.name.toUpperCase();
  }

  submit(): void {
    if (!this.data.voucher.name || this.data.voucher.name.includes('#')) {
      this.snackSvc.openSnackBar('Invalid name');
      return;
    }
    this.createVoucher();
  }

  createVoucher() {
    this.loading = true;
    const data = {
      'user_id': this.data.user.id,
      'name': this.data.voucher.name.toUpperCase(),
    };
    if (this.data.voucher.tour_cost || this.data.voucher.tour_cost === 0) {
      data['tour_cost'] = '' + this.data.voucher.tour_cost;
    }
    if (this.data.voucher.amount_off || this.data.voucher.amount_off === 0) {
      data['amount_off'] = '' + this.data.voucher.amount_off;
    }
    if (this.data.voucher.price_pday || this.data.voucher.price_pday === 0) {
      data['price_pday'] = '' + this.data.voucher.price_pday;
    }
    // console.log(data);
    this.httpOrders.storeVoucher(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 202) {
          this.data.voucher = res.results.voucher;
          this.close('save');
        } else {
          this.snackSvc.resultsElse(res);
        }
        this.loading = false;
      }, error => {
        this.loading = false;
        console.log(error);
      });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
