import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MainNewTourComponent} from './pages/main-new-tour/main-new-tour.component';
import {CompanySelectorComponent} from './components/company-selector/company-selector.component';
import {UploadFileComponent} from './components/upload-file/upload-file.component';
import {ManuallyCreateComponent} from './components/manually-create/manually-create.component';
import {NewtourService} from './services/newtour.service';
import {MaterialModule} from '../../../../shared/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SummaryNewTourComponent} from './pages/summary-new-tour/summary-new-tour.component';
import {SelectPaxComponent} from './pages/select-pax/select-pax.component';
import {SelectItinComponent} from './pages/select-itin/select-itin.component';
import {NewTourRoutingModule} from './new-tour-routing.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NewTourRoutingModule,
  ],
  exports: [
    UploadFileComponent,
  ],
  declarations: [
    MainNewTourComponent,
    CompanySelectorComponent,
    UploadFileComponent,
    ManuallyCreateComponent,
    SummaryNewTourComponent,
    SelectPaxComponent,
    SelectItinComponent,
  ],
  providers: [NewtourService]
})
export class NewTourModule {
}
