import {Injectable} from '@angular/core';
import {formatDate} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../session/authentication.service';
import {ActivetourService} from '../session/activetour.service';
import {Observable, Subject} from 'rxjs';
import {Activetour} from '../../models/activetour.model';
import {User} from '../../models/user.model';
import {OutlookService} from '../session/outlook.service';
import {SnackbarService} from './snackbar.service';
import {Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../../components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FlagService} from './flag.service';
import {Components} from '../../models/components.model';
import {Event} from '../../models/event.model';
import {HttpItineraryService} from '../http/http-itinerary.service';

@Injectable({
  providedIn: 'root'
})
export class TourService {
  activeTour: Activetour;
  currentUser: User;
  ub = false;
  et = false;
  gy = false;
  ad = false;
  ga = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService,
    private router: Router,
    private httpItin: HttpItineraryService,
    private flagService: FlagService,
    public dialog: MatDialog,
    private outlookSvc: OutlookService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.ub = tour?.tour.company_id === 2;
        this.et = tour?.tour.company_id === 3;
        this.ad = tour?.tour.company_id === 4;
        this.gy = tour?.tour.company_id === 5;
        this.ga = tour?.tour.company_id === 6;
      });
  }

  toggleVis(comp: Components, ev?: Event, ret?: boolean): Observable<any> {
    const payments = this.activeTour.orders.filter(o => o.status === 'Complete');
    if (payments.length <= 0) {
      const snackbarRef = this.snackSvc.openSnackBar('Purchase to publish events', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.router.navigate(['summary', this.activeTour.tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
          }
        });
      return;
    }
    if (!ev) {
      ev = comp.events[0];
    }
    let visible = false;
    if (!ev.visible) {
      visible = true;
    }
    ev.visible = visible;
    ev.show_times = visible;
    const params = {
      'req_id': this.currentUser.id,
      'visible': visible,
      'show_times': visible,
    };
    // console.log(params);
    if (ret) {
      return this.httpItin.updateEvent(ev.id, params);
    } else {
      this.httpItin.updateEvent(ev.id, params)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(res => {
          console.log(res);
          if (res.status < 400) {
            // Find the event in memory
            const eveIndex = this.activeTour.events.findIndex(evs => '' + evs.id === '' + ev.id);
            // Update in activeTour memory the new visibility
            this.activeTour.events[eveIndex] = res.results.event;
            comp.updated_at = res.results.event.updated_at;
          } else {
            ev.visible = !visible;
            ev.show_times = !visible;
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        }, error => {
          console.log(error);
        });
    }
  }

  onCreateTourFolder(verbose?) {
    if (+this.currentUser.view_as === +this.currentUser.id || !this.currentUser.view_as) {
      // Set Outlook folder name
      const data = {
        'user_id': this.currentUser.id,
        'tour_id': this.activeTour.tour.id,
        'session_id': localStorage.getItem('session_id'),
        'folder_name': formatDate(this.activeTour.tour.tour_starts, 'yy-MM-dd', 'en') + ' ' + this.activeTour.tour.code,
        // 'folder_name': this.activeTour.tour.prodid + ' ' + this.activeTour.tour.code,
        'ef_folder_id': this.currentUser.user_settings.ef_folder_id,
      };
      if (!environment.production) {
        data['test'] = true;
      }
      // console.log(data);
      this.outlookSvc.createTourFolder(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(res => {
          console.log(res);
          if (res.status < 400) {
            localStorage.setItem('folder_id', res.results.folder.id);
            if (verbose) {
              this.snackSvc.openSnackBar('Outlook folder reset');
            }
          } else {
            if (res.results) {
              if (verbose) {
                this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
              }
            } else {
              console.log(res.message.toString());
              if (res.message.includes('Error getting token')) {
                // Show dialog to fix problem
                const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                  data: {
                    autoFocus: true,
                    disabled: false,
                    confirmTitle: 'Attention',
                    confirmAction: 'Log out',
                    confirmMessage: 'Expired credentials. Please, log out and back in'
                  }
                });
                dialogRef.afterClosed().subscribe(reason => {
                  if (reason) {
                    this.authSvc.logout();
                    this.router.navigate(['login']);
                  }
                });
              } else {
                if (verbose) {
                  this.snackSvc.openSnackBar(res.message.toString());
                }
              }
            }
          }
        }, error => {
          console.log(error);
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error onCreateTourFolder: '
            + JSON.stringify(error), JSON.stringify(data));
          if (verbose) {
            this.snackSvc.openSnackBar('Error creating Outlook folder');
          }
        });
    }
  }

  onDeleteTourFolder() {
    if (!this.currentUser.view_as && localStorage.getItem('folder_id')) {
      const data = {
        'user_id': this.currentUser.id,
        'session_id': localStorage.getItem('session_id'),
        'folder_id': localStorage.getItem('folder_id'),
      };
      this.outlookSvc.deleteTourFolder(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status === 200) {
              return;
            } else {
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
            this.snackSvc.openSnackBar('Error deleting Outlook folder');
            this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error onDeleteTourFolder: '
              + JSON.stringify(error), JSON.stringify(data));
          });
    }
  }

  checkPax() {
    return this.activeTour.tour.npax - (this.activeTour.tour.rms.qad) * 4 - (this.activeTour.tour.rms.tri) * 3 -
      (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 - this.activeTour.tour.rms.sin -
      (this.activeTour.tour.rms.mal + this.activeTour.tour.rms.fem) !== 0;
  }
}
