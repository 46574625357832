import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

import {AuthGuard} from '../shared/guards/auth.guard';
import {AdminGuard} from '../shared/guards/admin.guard';
import {PaxGuardGuard} from '../shared/guards/pax-guard.guard';
import {ArraySortPipe} from '../shared/helpers/sort.pipe';
import {SplitPipe} from '../shared/helpers/split.pipe';
import {KeepHtmlPipe} from '../shared/helpers/keep-html.pipe';
import {FloorPipe} from '../shared/helpers/floor.pipe';
import {SearchPipe} from '../shared/helpers/search.pipe';
import {FilterPrefsPipe} from '../shared/helpers/filter-prefs.pipe';
import {FilterUserPipe} from '../shared/helpers/filter-user.pipe';
import {FilterTourPipe} from '../shared/helpers/filter-tour.pipe';
import {FilterCityPipe} from '../shared/helpers/filter-city.pipe';
import {FilterHotelPipe} from '../shared/helpers/filter-hotel.pipe';
import {FilterOrderPipe} from '../shared/helpers/filter-order.pipe';
import {NgxPicaModule} from '@digitalascetic/ngx-pica';

import {HomeComponent} from './pages/home/home.component';
import {UserProfileComponent} from './pages/user/user-profile/user-profile.component';
import {MaterialModule} from '../shared/material.module';
import {PaxinfoComponent} from './pages/tour/create-tour/paxinfo/paxinfo.component';
import {TourInfoComponent} from './pages/tour/create-tour/tour-info/tour-info.component';
import {FitineraryComponent} from './pages/tour/create-tour/fitinerary/fitinerary.component';
import {DayViewComponent} from './pages/tour/view-tour/day-view/day-view.component';
import {ItineraryViewComponent} from './pages/tour/view-tour/itinerary-view/itinerary-view.component';
import {ViewComponentComponent} from './pages/component/view-component/view-component.component';
import {LocatorComponent} from './pages/extras/locator/locator.component';
import {PaymentComponent} from './pages/tour/create-tour/payment/payment.component';
import {TourSettingsComponent} from './pages/tour/settings/tour-settings/tour-settings.component';
import {FormFlagsComponent} from './components/form-flags/form-flags.component';
import {IncidentComponent} from './pages/extras/incident/incident.component';
import {OptionalsComponent} from './pages/extras/optionals/optionals.component';
import {FreetimeComponent} from './pages/extras/freetime/freetime.component';
import {OrdersComponent} from './pages/user/orders/orders.component';
import {TourPaxComponent} from './pages/tour/settings/tour-pax/tour-pax.component';
import {TourOptionalsComponent} from './pages/tour/settings/tour-optionals/tour-optionals.component';
import {FaqsComponent} from './pages/support/faqs/faqs.component';
import {AdminComponent} from './pages/admin/admin/admin.component';
import {AdmLocatorComponent} from './pages/admin/adm-locator/adm-locator.component';
import {AdmUserComponent} from './pages/admin/adm-user/adm-user.component';
import {AdmToursComponent} from './pages/admin/adm-tours/adm-tours.component';
import {AdmFlagsComponent} from './pages/admin/adm-flags/adm-flags.component';
import {AdmIncidentsComponent} from './pages/admin/adm-incidents/adm-incidents.component';
import {AdmCitiesComponent} from './pages/admin/adm-cities/adm-cities.component';
import {AdmHotelsComponent} from './pages/admin/adm-hotels/adm-hotels.component';
import {AddCityComponent} from './components/add-city/add-city.component';
import {AddVoucherComponent} from './components/admin/add-voucher/add-voucher.component';
import {AddCreditComponent} from './components/admin/add-credit/add-credit.component';
import {AddHotelComponent} from './components/hotel/add-hotel/add-hotel.component';
import {TourOptPaxComponent} from './pages/pax/tour-opt-pax/tour-opt-pax.component';
import {CopyComponentComponent} from './components/tour/copy-component/copy-component.component';
import {EditUserComponent} from './components/admin/edit-user/edit-user.component';
import {EditTourComponent} from './components/admin/edit-tour/edit-tour.component';
import {DupeTourComponent} from './components/tour/dupe-tour/dupe-tour.component';
import {DupeItinComponent} from './components/admin/dupe-itin/dupe-itin.component';
import {UpdatePaxComponent} from './components/tour/update-pax/update-pax.component';
import {CreateInvoiceComponent} from './components/admin/create-invoice/create-invoice.component';
import {CopyFromComponent} from './components/tour/copy-from/copy-from.component';
import {TourHotelsComponent} from './pages/tour/settings/tour-hotels/tour-hotels.component';
import {EditRoomsComponent} from './components/hotel/edit-rooms/edit-rooms.component';
import {AddPaxComponent} from './components/tour/add-pax/add-pax.component';
import {AddRoomieComponent} from './components/hotel/add-roomie/add-roomie.component';
import {AssignRoomsComponent} from './components/hotel/assign-rooms/assign-rooms.component';
import {SendRoomingComponent} from './components/hotel/send-rooming/send-rooming.component';
import {ReplicateRoomsComponent} from './components/hotel/replicate-rooms/replicate-rooms.component';
import {AdmOrdersComponent} from './pages/admin/adm-orders/adm-orders.component';
import {PaxTipsComponent} from './pages/pax/pax-tips/pax-tips.component';
import {HomePaxComponent} from './pages/pax/home-pax/home-pax.component';
import {EditOrdersComponent} from './components/admin/edit-orders/edit-orders.component';
import {EditVouchersComponent} from './components/admin/edit-vouchers/edit-vouchers.component';
import {DonationComponent} from './pages/pax/donation/donation.component';
import {EditPaxComponent} from './components/tour/edit-pax/edit-pax.component';
import {AdmCompsComponent} from './pages/admin/adm-comps/adm-comps.component';
import {FilterOrdersComponent} from './components/admin/filter-orders/filter-orders.component';
import {AdmStripeEvtsComponent} from './pages/admin/adm-stripe-evts/adm-stripe-evts.component';
import {FilterStripeEvtsPipe} from '../shared/helpers/filter-stripe-evts.pipe';
import {TimeChangerComponent} from './components/tour/time-changer/time-changer.component';
import {AdmActsComponent} from './pages/admin/adm-acts/adm-acts.component';
import {AddActComponent} from './components/activity/add-act/add-act.component';
import {FilterActPipe} from '../shared/helpers/filter-act.pipe';
import {AddUpgRoomComponent} from './components/hotel/add-upg-room/add-upg-room.component';
import {TourNotesComponent} from './components/tour/tour-notes/tour-notes.component';
import {GrantAccessComponent} from './components/tour/grant-access/grant-access.component';
import {AddOptionalComponent} from './components/tour/add-optional/add-optional.component';
import {ContactsListComponent} from '../shared/components/contacts-list/contacts-list.component';
import {TDIcompsComponent} from '../shared/components/tdicomps/tdicomps.component';
import {OpenGalleryComponent} from '../shared/components/open-gallery/open-gallery.component';
import {RecipientsComponent} from '../shared/components/recipients/recipients.component';
import {UpdateVersionComponent} from './components/update-version/update-version.component';
import {FormComponent} from './pages/component/form/form.component';
import {MainTourHotelsComponent} from './pages/tour/settings/main-tour-hotels/main-tour-hotels.component';
import {MainTourOptionalsComponent} from './pages/tour/settings/main-tour-optionals/main-tour-optionals.component';
import {TourFlightsComponent} from './pages/tour/settings/tour-flights/tour-flights.component';
import {AddFlightComponent} from './components/tour/add-flight/add-flight.component';
import {NewAnswerComponent} from './pages/component/form/new-answer/new-answer.component';
import {ScheduleSendComponent} from './components/tour/schedule-send/schedule-send.component';
import {EventViewComponent} from './pages/tour/view-tour/common/event-view/event-view.component';
import {AddPaxListComponent} from './components/tour/add-pax-list/add-pax-list.component';
import {AdfreeComponent} from './pages/pax/adfree/adfree.component';
import {NewTourModule} from './pages/tour/new-tour/new-tour.module';

const leaderRoutes: Routes = [
  {path: 'access', component: HomeComponent},
  {
    path: 'support', canActivate: [AuthGuard], children: [
      {path: '', component: FaqsComponent},
      {path: ':faq', component: FaqsComponent}
    ]
  },
  {path: 'verify_access', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'tours', component: HomeComponent, canActivate: [AuthGuard]},
  {
    path: 'admin', canActivate: [AuthGuard, AdminGuard], children: [
      {path: '', component: AdminComponent},
      {path: 'users', component: AdminComponent},
      {path: 'tours', component: AdminComponent},
      {path: 'activities', component: AdminComponent},
      {path: 'hotels', component: AdminComponent},
      {path: 'comps', component: AdminComponent},
      {path: 'cities', component: AdminComponent},
      {path: 'orders', component: AdminComponent},
      {path: 'flags', component: AdminComponent},
      {path: 'stripevts', component: AdminComponent},
      {path: 'incidents', component: AdminComponent},
      {path: 'locators', component: AdminComponent},
    ]
  },
  {path: 'finder', component: LocatorComponent, canActivate: [AuthGuard]},
  {path: 'incidents', component: IncidentComponent, canActivate: [AuthGuard]},
  {
    path: 'activities', canActivate: [AuthGuard], children: [
      {path: '', component: OptionalsComponent},
      {path: ':city_name', component: OptionalsComponent}
    ]
  },
  {
    path: 'freetime', canActivate: [AuthGuard], children: [
      {path: '', component: FreetimeComponent},
      {
        path: ':city_name', children: [
          {path: '', component: FreetimeComponent},
          {path: ':ft_type/:ft_name', component: FreetimeComponent}
        ]
      }
    ]
  },
  {
    path: 'tourinfo', canActivate: [AuthGuard], children: [
      {path: '', component: TourInfoComponent},
      {path: ':tour-prodid', component: TourInfoComponent},
    ]
  },
  {
    path: 'itin', canActivate: [AuthGuard], children: [
      {path: '', component: FitineraryComponent},
      {
        path: ':tour-prodid', canActivate: [AuthGuard], children: [
          {path: '', component: FitineraryComponent},
          {path: 'edit', component: FitineraryComponent}
        ]
      }
    ]
  },
  {
    path: 'paxinfo', canActivate: [AuthGuard], children: [
      {path: '', component: PaxinfoComponent},
      {path: ':tour-prodid', component: PaxinfoComponent}
    ]
  },
  {
    path: 'checkout', canActivate: [AuthGuard], children: [
      {path: '', component: PaymentComponent},
      {path: ':tour-prodid', component: PaymentComponent}
    ]
  },
  {
    path: 'tour', canActivate: [AuthGuard], children: [
      {path: '', component: ItineraryViewComponent},
      {
        path: ':tour-prodid', canActivate: [AuthGuard], children: [
          {path: '', component: ItineraryViewComponent},
          {
            path: 'day/:day_id', canActivate: [AuthGuard], children: [
              {path: '', component: DayViewComponent},
              {path: 'component/:component_id', component: ViewComponentComponent},
              {path: 'component/new', component: ViewComponentComponent},
            ]
          },
          {path: 'settings', component: TourSettingsComponent},
          {path: 'flights', component: TourFlightsComponent},
          {path: 'tourpax', component: TourPaxComponent},
          {
            path: 'activities', canActivate: [AuthGuard], children: [
              {path: '', component: MainTourOptionalsComponent},
              {path: ':to_id', component: TourOptionalsComponent}
            ]
          },
          {
            path: 'hotels', canActivate: [AuthGuard], children: [
              {path: '', component: MainTourHotelsComponent},
              {path: ':th_id', component: TourHotelsComponent}
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'user', canActivate: [AuthGuard], children: [
      {path: 'info', component: UserProfileComponent},
      {path: 'options', component: UserProfileComponent},
      {path: 'templates', component: UserProfileComponent},
      {path: 'referrals', component: UserProfileComponent},
    ]
  },
  {
    path: 'orders', canActivate: [AuthGuard], children: [
      {path: '', component: OrdersComponent},
      {path: ':order_id', component: OrdersComponent}
    ]
  },
  {
    path: 'home', canActivate: [PaxGuardGuard], children: [
      {path: '', component: HomePaxComponent},
    ]
  },
  {
    path: 'optional_activity', canActivate: [PaxGuardGuard], children: [
      {path: '', component: TourOptPaxComponent},
      {path: ':tour_id', component: TourOptPaxComponent},
      {path: 'to/:to_id', component: TourOptPaxComponent},
      {path: 'top/:top_id', component: TourOptPaxComponent}
    ]
  },
  {
    path: 'appreciation', canActivate: [PaxGuardGuard], children: [
      {path: '', component: PaxTipsComponent},
      {path: ':tour_id', component: PaxTipsComponent},
    ]
  },
  {
    path: 'donate', component: DonationComponent, canActivate: [PaxGuardGuard],
  },
  {
    path: 'upgrade', component: AdfreeComponent, canActivate: [PaxGuardGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(leaderRoutes),
    FormsModule,
    NgxPicaModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NewTourModule,
    CKEditorModule
  ],
  declarations: [
    HomeComponent,
    FitineraryComponent,
    TourInfoComponent,
    PaxinfoComponent,
    DayViewComponent,
    ItineraryViewComponent,
    ViewComponentComponent,
    FormComponent,
    EventViewComponent,
    LocatorComponent,
    PaymentComponent,
    IncidentComponent,
    TourSettingsComponent,
    TourFlightsComponent,
    TourPaxComponent,
    TourOptionalsComponent,
    TourHotelsComponent,
    MainTourHotelsComponent,
    MainTourOptionalsComponent,
    UserProfileComponent,
    AdminComponent,
    AdmUserComponent,
    AdmToursComponent,
    AdmFlagsComponent,
    AdmIncidentsComponent,
    AdmCitiesComponent,
    AdmLocatorComponent,
    AdmHotelsComponent,
    AdmActsComponent,
    AdmOrdersComponent,
    AdmCompsComponent,
    AdmStripeEvtsComponent,
    AddCreditComponent,
    OptionalsComponent,
    FreetimeComponent,
    OrdersComponent,
    FaqsComponent,
    ArraySortPipe,
    SplitPipe,
    FloorPipe,
    KeepHtmlPipe,
    SearchPipe,
    FilterPrefsPipe,
    FilterUserPipe,
    FilterTourPipe,
    FilterCityPipe,
    FilterHotelPipe,
    FilterActPipe,
    FilterOrderPipe,
    FilterStripeEvtsPipe,
    DupeTourComponent,
    DupeItinComponent,
    GrantAccessComponent,
    AddFlightComponent,
    AddOptionalComponent,
    ReplicateRoomsComponent,
    EditRoomsComponent,
    AddCityComponent,
    EditUserComponent,
    EditTourComponent,
    EditOrdersComponent,
    EditVouchersComponent,
    EditPaxComponent,
    FilterOrdersComponent,
    UpdatePaxComponent,
    CopyComponentComponent,
    CopyFromComponent,
    ContactsListComponent,
    TDIcompsComponent,
    OpenGalleryComponent,
    RecipientsComponent,
    UpdateVersionComponent,
    AddHotelComponent,
    AddActComponent,
    AddPaxComponent,
    AddPaxListComponent,
    AddVoucherComponent,
    AddRoomieComponent,
    AddUpgRoomComponent,
    TourNotesComponent,
    SendRoomingComponent,
    AssignRoomsComponent,
    FormFlagsComponent,
    NewAnswerComponent,
    CreateInvoiceComponent,
    TourOptPaxComponent,
    PaxTipsComponent,
    DonationComponent,
    AdfreeComponent,
    HomePaxComponent,
    TimeChangerComponent,
    ScheduleSendComponent
  ]
})

export class HomeRoutingModule {
}
