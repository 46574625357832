import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewtourService {

  // Use BehaviorSubject to enable observing and updating the variable
  private manuallySource = new BehaviorSubject<boolean>(false);
  manually$ = this.manuallySource.asObservable(); // Observable for components to subscribe

  // Use BehaviorSubject to enable observing and updating the variable
  private resSource = new BehaviorSubject<any>(false);
  res$ = this.resSource.asObservable(); // Observable for components to subscribe

  setManually(value: boolean) {
    this.manuallySource.next(value); // Update value across components
  }

  setRes(value: any) {
    this.resSource.next(value); // Update value across components
  }

}
